export const TERMS_LIST = [
  {
    id: 'terms-of-service',
    title: 'Terms of Service',
    subtitle: 'These are the terms you agree to when you use Sitelio.',
  },
  {
    id: 'privacy-notice',
    title: 'Privacy Notice',
    subtitle:
      'This Privacy Notice applies to the personal data of users collected or used by Newfold Digital, Inc, doing business as Sitelio.',
  },
  {
    id: 'acceptable-use',
    title: 'Acceptable Use',
    subtitle:
      "This Acceptable Use Policy ('AUP') governs your use of the Services and is incorporated by reference into Sitelio's Terms and Conditions.",
  },
  {
    id: 'trademark-disputes',
    title: 'Trademark Disputes',
    subtitle: 'Sitelio Trademark Disputes for Domain Names.',
  },
  {
    id: 'domain-registration-agreement',
    title: 'Domain Registration',
    subtitle: 'Sitelio Domain Registration Agreement.',
  },
  {
    id: 'copyright-infringement-policy',
    title: 'Copyright',
    subtitle: 'Copyright Infringement Policy.',
  },
  {
    id: 'dnrn-policy',
    title: 'DNRN Policy',
    subtitle: 'Sitelio Domain Name Renewal Notification Policy.',
  },
  {
    id: 'data-request-policy',
    title: 'Data Request Policy',
    subtitle: 'Sitelio Data Request Policy.',
  },
  {
    id: 'arbitration-agreement',
    title: 'Arbitration Agreement',
    subtitle: 'Sitelio Arbitration Agreement.',
  },
  {
    id: 'professional-services-agreement',
    title: 'Professional Services Agreement',
    subtitle: 'Sitelio Professional Services Agreement.',
  },
]
